.login-container {
    width: 100%;
    height: 100%;
    background-color:#ffffff;
    position: relative;
    overflow: hidden;
    border: 0px solid #f6d98f;
    box-shadow: -2px -2px 20px rgb(20, 20, 20);
    border-radius: 0px;
    /*background-image: url("../kmbatlogin.jpg") !important;
    background-size: cover !important;
    background-position: right !important;*/
}

.btn-grad {
    background-image: linear-gradient(to right, #00c6ff 0%, #0072ff  51%, #00c6ff  100%);
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
}

.btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}



.ant-layout-sider-trigger {
    background-color: #262626 !important;
}
.logonav{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}
.logoApp{
    /*background-image: url("../logosavdo.png");*/
    background-image: url("../logokmbat.svg");
    width: 45px;
    height: 40px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 22px 24px;
    background-position: center;
}




.content-index {
    background: rgb(29,174,215);
    background: radial-gradient(circle, rgba(29,174,215,1) 20%, rgba(124,237,255,1) 60%, rgba(169, 225, 172) 100%) ;
    background-image: url("../bgkmbat1.jpg") !important;
    background-image: url("../bgkmbat2.jpg") !important;
    background-size: cover !important;
    background-position: right !important;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    flex-grow:1;
    padding: 24px;
    min-height: 100%;
    overflow: auto;
    position: relative
}

.footer-index {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color:transparent;
}

.login-container-index {
    flex: 1 1 15%;
    border-bottom-left-radius: 50%;
    height: 100%;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
}

.svg-container {
    flex: 1 1 50%;
    border: 0px solid yellow;
    width: 100%;
    height: 100%;
    position: relative;

}
.logobm{
    background-image: url("../loginbg2.jpg");
    /*background-image: url("../loginbg3.svg");
    background-image: url("../wawegold.png");
    background-image: url("../12310.png");*/
    /*background-image: url("../geometric_perspective_line_pattern_background.jpg");*/
    /*background-image: url("../5968959.jpg");*/
    background: transparent;
    background-size: cover;
    background-position: left;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;

    /*display: inline-block;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
}

.svg-content {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
}

.logo-container {
    /*background: url("../iconBook.png");*/
    /*background: url("../bmlogo4.svg");*/
    /*background-size: 150px 90px;*/
    /*background-repeat: no-repeat;*/
    position: absolute;
    width: 450px;
    height: 100px;
    white-space: pre;
    top: 40%;
    left: 30px;
    background: transparent;
    /*transform: translate(-50%,-50%);*/
}
.logo-title h4, .logo-title h6{
    width: auto;
    white-space: pre;
    font-size: 21px;
    padding-left: 60px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    color: #f6ffd6;
    font-weight: normal;
}

.login-illustrate {
    width: 400px;
    height: 400px;
    margin-top: 0px;
    background-color: transparent;
    background-image: url("../kmbatillustrate3.svg");
    display: inline-block;
    position: absolute;
    left: calc(50% - 200px);
    bottom: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    left: calc(50% - 200px);
    top: -30px;
}

.logo-img {
 /*   background: url("../bmlogo4.svg");
    background-image: url("../new-relic-icon.svg");
    background-image: url("../noysi.svg");
    background-image: url("../jetbrains-space-icon.svg");
    background-image: url("../Gold horizontal.png");

    background-image: url("../Gold horizontal 2.png");
    background-image: url("../Gold vertical 2.png");
    background-image: url("../Gold vertical.png");*/
    background-image: url("../logotitle.png");
    background-image: url("../logotitle2.png");
    background-size: 410px 98px;
    background-repeat: no-repeat;
    width: 450px;
    height: 100px;
    display: inline-block;
    clear: right;
    float: left;
}

.logo-title h6{
    font-size: 18px;
    color: #e1e1e1;
}
.logo-title h6{
    margin-top: -12px;
}

/*.login-eff {
    background: url("../Untitled.svg");
    background-size: 500px 500px;
    background-repeat: no-repeat;
    width: 500px;
    height: 500px;
    display: block;
    position: absolute;
    z-index: 0;
    bottom: -158px;
    right: -199px;

}*/

.page-wrapper {
    box-shadow: 0px 0px 16px 1px #2875c8;
}

.list{
    display: flex;
    padding: 6px 10px;
}
.list:nth-child(even) {
    background-color: #f2f2f2;
  }
.key {
    flex: 0.4;
    font-weight: normal;
}
.value {
    flex: 0.6;
    font-weight: bold;
}

.mud__login-page {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mud__title {
    color: white !important;
    width: 230px !important;
    fontSize: 24px !important;
    textAlign: center !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: #d31a1a00 !important;
}

.mud__login-form-container {
    position: absolute;
    z-index: 3;
    /*height: 520px;
    width: 400px;*/
    background-color: rgba(5, 19, 6, 0.65);
    /*position: absolute;
    transform: translate(-50%, -50%);*/
    /*top: 50%;
    left: 50%;*/
    border-radius: 10px;
    /*backdrop-filter: blur(10px);*/
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(8px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    /*padding: 50px 35px;*/
}

.mud__video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0; /* Отправляет видео на задний план */
}

.ins__login-form-container {
    position: absolute;
    z-index: 3;
    /*height: 520px;
    width: 400px;*/
    background-color: rgba(5, 19, 6, 0.65);
    /*position: absolute;
    transform: translate(-50%, -50%);*/
    /*top: 50%;
    left: 50%;*/
    border-radius: 10px;
    /*backdrop-filter: blur(10px);*/
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(8px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    /*padding: 50px 35px;*/
}

.logoAppIns{
    /*background-image: url("../logosavdo.png");*/
    background-image: url("../insurance.svg");
    background-size: 450px 120px;
    background-repeat: no-repeat;
    width: 400px;
    height: 150px;
    display: inline-block;
    background-position: center;
}

.ins__login-page {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(287.56deg, #15222B 0%, #396342 100%);

}


.ins__login-form-container {
    position: absolute;
    z-index: 3;
    /*height: 520px;
    width: 400px;*/
    background-color: rgba(255,255,255,0.05);
    /*position: absolute;
    transform: translate(-50%, -50%);*/
    /*top: 50%;
    left: 50%;*/
    border-radius: 10px;
    /*backdrop-filter: blur(10px);*/
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(8px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    /*padding: 50px 35px;*/
}