.highcharts-figure,
.highcharts-data-table table {
    min-width: 320px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
.chart-container {
    width: 100%;
    max-width: 100%;
    max-height: 500px;
    border: 1px solid #ccc;
    overflow: auto;
}
.chart-line-container {
    overflow-x: auto;
    overflow-y: auto;
    max-width: 90vw;
}

.react_chart_area {
    height: 90vh;
    width: auto;
}

/*.echarts-for-react {
    height: 90vh;
    width: 90%;
}*/
.react_chart_area:first-child {
    min-width: 1200px !important;
    height: 600px !important;
}

.echarts-for-react {
    min-height: 400px;
}
